import { SelectionChangedEvent } from "ag-grid-community";
import { ChargeConfirmedEntity } from "domain/entity/ChargeConfirmed/ChargeConfirmedEntity";
import { transferRowData } from "presentation/constant/ChargeConfirmed/ChargeConfirmedColumnDefinition";
import { useChargeConfirmedVM } from "presentation/hook/ChargeConfirmed/useChargeConfirmedVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChargeConfirmedTracked } from "presentation/store/ChargeConfirmed/ChargeConfirmedProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";


const ChargeConfirmedTablePanel: React.FC = () => {
    const [chargeConfirmedState] = useChargeConfirmedTracked();
    const chgConfVM = useChargeConfirmedVM();
    const [anaInfoState] = useANAInfoTracked();
    let gridRef: any = useRef();

    const { isShowAll, chargeConfirmedList } = chargeConfirmedState;


    // useEffect(() => {
    //     if (onTableSelectionClicked) return;

    //     gridRef.current?.gridRef?.current?.api?.deselectAll();
    // }, [onTableSelectionClicked])

    useEffect(() => {
        if (chargeConfirmedState.isDeselectAll) {
            gridRef.current?.gridRef?.current?.api?.deselectAll();
        }

    }, [chargeConfirmedState.isDeselectAll])

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        chgConfVM.updateSelectedRows(selectedRows);
    }, [chgConfVM])

    const handleRowClick = useCallback(async (chargeConfrimedRow: ChargeConfirmedEntity) => {
        chgConfVM.onRowClick(chargeConfrimedRow);
        // chgConfVM.onDetailSearch(chargeConfrimedRow);
    }, [chgConfVM])

    const memoChgConfTable = useMemo(() => {
        return (
            <>
                <NbisTable
                    id='charge-confirmed-list-table'
                    isNewColumnSetting={true}
                    columns={chargeConfirmedState.dynamicTableColumns.slice()}
                    //columns={INITIAL_CHG_CONF_COL_DEF.slice()}
                    data={transferRowData(isShowAll ? (chargeConfirmedList ?? [])
                        : (chargeConfirmedList?.filter(row => row.confirmedBy === anaInfoState.userName) ?? []))}
                    //onRowClick={(e: any) => handleRowClick(e.data)}
                    showPaginator={false}
                    editable={false}
                    showHeaderIcons={true}
                    showAddIcon={false}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    selectionMode={false}
                    rowSelection={"multiple"}
                    onSelectionChanged={handleSelectionChange}
                    onRowDoubleClick={(e: any) => handleRowClick(e.data)}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 125px)"
                    ref={gridRef}
                />
            </>
        );
    }, [anaInfoState.userName, chargeConfirmedList, chargeConfirmedState.dynamicTableColumns, handleRowClick, handleSelectionChange, isShowAll])

    return <><TableWrapper>{memoChgConfTable}</TableWrapper></>;
}

export default memo(ChargeConfirmedTablePanel);
